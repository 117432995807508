import React from "react";
import "./Product.scss";
import { useNavigate } from "react-router-dom";
export default function Product(props) {
  const isProductOnSale = parseFloat(props.salePrice) > 0 ? true : false;
  const navigate = useNavigate();
  const productClickedHandler = () => {
    if (props.isActive === 1) {
      navigate("/proizvod/" + props.id);
    }
  };
  return (
    <div
      className={`product ${props.isActive === 0 ? "inactive" : ""}`}
      onClick={productClickedHandler}
    >
      <div className="product__image">
        <img
          src={`${process.env.REACT_APP_BASE_URL}/` + props.imageUrl}
          alt={props.name}
        />
      </div>
      <div className="product__details">
        <span className="product__details-title">{props.name}</span>
        <span className="product__details-code">Šifra: {props.code}</span>
        <div className="product__prices">
          <span
            className={`product__prices__price ${
              isProductOnSale ? "unactive" : ""
            }`}
          >
            {props.price.toFixed(2)} KM
          </span>
          {isProductOnSale && (
            <span className="product__prices__sale-price">
              {props.salePrice.toFixed(2)} KM
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
