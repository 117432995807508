import React, { useRef, useState } from "react";
import "./ProductSlider.scss";
import ProductSliderItem from "../ProductSliderItem/ProductSliderItem";
import { useNavigate } from "react-router-dom";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
export default function ProductSlider(props) {
  const navigate = useNavigate();
  const sliderRef = useRef(null);
  const productClicked = (prodId) => {
    props.setImageIndex(0);
    navigate("/proizvod/" + prodId);
  };

  const handleClickLeft = () => {
    sliderRef.current.scrollLeft -= 200;
  };

  const handleClickRight = () => {
    sliderRef.current.scrollLeft += 200;
  };

  return (
    <div className="product-slider-container">
      <span className="product-slider__title">
        Ostalo iz {props.categoryTitle}
      </span>
      <div className="product-slider" ref={sliderRef}>
        <div className="slider">
          {props.products.map((product) => (
            <ProductSliderItem
              key={product.id + Math.random()}
              title={product.title}
              image={product.image}
              id={product.id}
              productClicked={productClicked}
            />
          ))}
        </div>
      </div>
      {!props.isMobile && (
        <div className="slider-arrows">
          <span className="slider-arrows__left" onClick={handleClickLeft}>
            <FaAngleLeft className="left" />
          </span>
          <span className="slider-arrows__right" onClick={handleClickRight}>
            <FaAngleRight className="right" />
          </span>
        </div>
      )}
    </div>
  );
}
