import React from "react";
import "./Layout.scss";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";

export default function Layout(props) {
  return (
    <div className="layout">
      <div className="navigation-bar">
        <div className="logo">
          <img src="/miniplast-logo.png" alt="logo" />
        </div>
        <ul className="nav-items">
          <li>
            <a href="/">Početna</a>
          </li>
          <li>
            <a href="/katalog">Katalog</a>
          </li>
          <li>
            <a href="/zastupništvo">Zastupništvo</a>
          </li>
          <li>
            <a href="/o-nama">O nama</a>
          </li>
          <li>
            <a href="/kontakt">Kontakt</a>
          </li>
          {props.saleProducts?.length > 0 && (
            <li className="sale-link">
              <a href="/miniplast-akcije">Akcija</a>
            </li>
          )}
        </ul>
      </div>
      <div className="page-content">{props.children}</div>
      <div className="footer">
        <p>Copyrights © Miniplast</p>
        <div className="footer-line"></div>
        <div className="social-media">
          <a
            href="https://www.facebook.com/MiniplastDOO/"
            rel="noreferrer"
            target="_blank"
            className="miniplast-facebook"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.instagram.com/miniplast_doo/"
            rel="noreferrer"
            target="_blank"
            className="miniplast-instagram"
          >
            <FaInstagram />
          </a>
          <a
            href="/#"
            rel="noreferrer"
            target="_blank"
            className="miniplast-linkedin"
          >
            <FaLinkedinIn />
          </a>
        </div>
      </div>
    </div>
  );
}
