import React from "react";
import "./HomeNews.scss";
export default function HomeNews(props) {
  return (
    <a
      className={`home-news ${props.extraClass ? props.extraClass : ""}`}
      href={props.link}
      target="_blank"
      rel="noreferrer"
    >
      <div className="home-news__img">
        <img src={`./home-news/${props.image}`} alt={props.image} />
      </div>
      <div className="home-news__details">
        <p className="home-news__details-text">{props.details}</p>
      </div>
    </a>
  );
}
