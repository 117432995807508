import React, { useState } from "react";
import "./MobileLayout.scss";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
export default function MobileLayout(props) {
  const [isNavShowed, setIsNavShowed] = useState(false);
  const toggleNavBar = () => {
    setIsNavShowed((prevVal) => !prevVal);
  };
  return (
    <div className="m-layout">
      <div className={`m-navigation-bar ${isNavShowed ? "override" : ""}`}>
        <div className="m-logo">
          <img src="/miniplast-logo.png" alt="logo" />
        </div>
        <div className={`m-nav ${!isNavShowed ? "hidden" : ""}`}>
          <ul className="m-nav-items">
            <li>
              <a href="/">Početna</a>
            </li>
            <li>
              <a href="/katalog">Katalog</a>
            </li>
            <li>
              <a href="/zastupništvo">Zastupništvo</a>
            </li>
            <li>
              <a href="/o-nama">O nama</a>
            </li>
            <li>
              <a href="/kontakt">Kontakt</a>
            </li>
            {props.saleProducts?.length > 0 && (
              <li className="sale-link">
                <a href="/miniplast-akcije">Akcija</a>
              </li>
            )}
          </ul>
          <div className="burger" onClick={toggleNavBar}>
            <div
              className={`burger-item-one ${isNavShowed ? "rotate" : ""}`}
            ></div>
            {!isNavShowed && <div className="burger-item-two"></div>}
            <div
              className={`burger-item-three ${isNavShowed ? "rotate" : ""}`}
            ></div>
          </div>
        </div>
      </div>
      <div className="m-page-content">{props.children}</div>
      <div className="m-footer">
        <p>Copyrights © Miniplast</p>
        <div className="m-footer-line"></div>
        <div className="m-social-media">
          <a
            href="https://www.facebook.com/MiniplastDOO/"
            rel="noreferrer"
            target="_blank"
            className="miniplast-facebook"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.instagram.com/miniplast_doo/"
            rel="noreferrer"
            target="_blank"
            className="miniplast-instagram"
          >
            <FaInstagram />
          </a>
          <a
            href="/#"
            rel="noreferrer"
            target="_blank"
            className="miniplast-linkedin"
          >
            <FaLinkedinIn />
          </a>
        </div>
      </div>
    </div>
  );
}
