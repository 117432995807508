import React from "react";
import "./ProductSliderItem.scss";
export default function ProductSliderItem(props) {
  const clickedHandler = () => {
    props.productClicked(props.id);
  };
  return (
    <div className="product-slider-item" onClick={clickedHandler}>
      <div className="product-slider-item__img">
        <img
          src={process.env.REACT_APP_BASE_URL + "/" + props.image}
          alt={props.title}
        />
      </div>
      <span className="product-slider-item__title">{props.title}</span>
    </div>
  );
}
