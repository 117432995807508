import React, { useEffect, useState } from "react";
import "./Home.scss";
import HomeNews from "../../UI/HomeNews/HomeNews";
export default function Home(props) {
  const [imageIndex, setImageindex] = useState(0);
  useEffect(() => {
    let interval = setInterval(() => {
      if (imageIndex === 4) {
        setImageindex(0);
      } else {
        setImageindex((prev) => prev + 1);
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [imageIndex]);
  return (
    <div className="miniplast-home">
      <div className="home-carousel">
        <div className="carousel">
          <img
            src="./carousel/napa.jpeg"
            className={imageIndex === 0 ? "show" : ""}
            alt="img-5"
          />
          <img
            src="./carousel/sudoper-kompakt.jpeg"
            className={imageIndex === 1 ? "show" : ""}
            alt="img-2"
          />
          <img
            src="./carousel/office-miniplast.jpeg"
            className={imageIndex === 2 ? "show" : ""}
            alt="img-1"
          />
          <img
            src="./carousel/oprema-za-dom.jpeg"
            className={imageIndex === 3 ? "show" : ""}
            alt="img-3"
          />
          <img
            src="./carousel/slavine.jpeg"
            className={imageIndex === 4 ? "show" : ""}
            alt="img-4"
          />
        </div>
        <div className="dots">
          <div className="first-dot" onClick={() => setImageindex(0)}></div>
          <div className="second-dot" onClick={() => setImageindex(1)}></div>
          <div className="third-dot" onClick={() => setImageindex(2)}></div>
          <div className="fourth-dot" onClick={() => setImageindex(3)}></div>
          <div className="fifth-dot" onClick={() => setImageindex(4)}></div>
        </div>
      </div>
      <div className="news-section">
        <h2>Novosti</h2>
        <div className="news-list">
          <HomeNews
            image={"lama-brusched.jpg"}
            link={"https://www.paludetmario.it/english/lama-pet-line.html"}
            details={
              "Paludet kolekcija obogaćena je novim LAMA asortimanom. Paneli koji izgledaju kao aluminij sada su obrubljeni čeličnim i ravnim prijedlozima dekora"
            }
          />
          <HomeNews
            image={"hugo-solutions.jpg"}
            link={"https://www.aludesignitaly.it/aluminum-processing/"}
            details={
              "HUGO je modularan, elegantan i svestran sustav koji nudi rješenje za sve načine opremanja. Dostupan je u verzijama pod/strop, samonosivost, te pod/zid"
            }
            extraClass={!props.isMobile ? "reverse" : ""}
          />
          <HomeNews
            image={"yo-yo.jpg"}
            link={"https://www.sige-spa.it/en/cataloghi/810pro/"}
            details={
              "YO-YO mehanizam na spuštanje za gornji ormarić, s mekim zatvaranjem i košarom od lakiranog lima. Moguće su jedna ili dvije police."
            }
          />
        </div>
      </div>
    </div>
  );
}
