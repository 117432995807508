import React, { useEffect, useState } from "react";
import "./CategoriesMenu.scss";
import { useParams, useNavigate } from "react-router-dom";
import { FaSearch } from "react-icons/fa";
import Product from "../../UI/Product/Product";
import Spinner from "../../UI/Spinner/Spinner";
export default function CategoriesMenu(props) {
  const param = useParams();
  const navigate = useNavigate();
  const urlParam = param.categoryId;
  let categoryTitle, productsOfCategory;
  if (urlParam) {
    categoryTitle = props.dataItems["kategorije"]?.find(
      (category) => category.id === +urlParam
    )?.title;
    productsOfCategory = props.dataItems["proizvodi"]?.filter(
      (product) => product.categoryID === +urlParam
    );
  } else {
    categoryTitle = "Akcija";
    productsOfCategory = props.dataItems;
  }

  const [sortOrderId, setSortOrderId] = useState(0);
  const [productsPerPage] = useState(10);
  const [counter, setCounter] = useState(1);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);
  const getProductsForShow = () => {
    const paginationParam = window.location.search?.split("=");
    let productsLength, products;

    if (!urlParam) {
      products = props.dataItems;
      productsLength = props.dataItems?.length;
    } else {
      products = props.dataItems["proizvodi"]?.filter(
        (product) => product.categoryID === +urlParam
      );
      productsLength = products.length;
    }
    if (productsLength > 0) {
      if (paginationParam.length < 2) {
        // Ako nema query parametar page i ako ima artikala

        return products
          ?.slice(0, productsPerPage)
          ?.sort((a, b) => a.ordinalNum - b.ordinalNum);
      } else {
        const pageNum = +paginationParam[1];
        let rangeFrom = (pageNum - 1) * productsPerPage;
        let rangeTo = parseInt(pageNum) * productsPerPage;

        if (productsLength > pageNum * productsPerPage) {
          // Ako pregledava po stranici brojeve i nije do kraja paginacije došao
          return products
            ?.slice(rangeFrom, rangeTo)
            ?.sort((a, b) => a.ordinalNum - b.ordinalNum);
        } else {
          // Ako je do kraja paginacije došao
          if (pageNum * productsPerPage - productsLength < productsPerPage) {
            // Ako si na zadnjoj stranici paginacije

            return products
              ?.slice(rangeFrom, productsLength)
              ?.sort((a, b) => a.ordinalNum - b.ordinalNum);
          } else {
            return [];
          }
        }
      }
    }
  };
  useEffect(() => {
    const products = getProductsForShow();
    const paginationOptions = getPaginationOptions();
    setIsLoadingProducts(true);
    setTimeout(() => {
      setIsLoadingProducts(false);
      setProducts(products);
      setPaginationOptions(paginationOptions);
    }, 1000);
  }, [window.location.href]);
  const getPaginationOptions = () => {
    const paginationParam = window.location.search?.split("=");
    let productsLength, products;

    if (!urlParam) {
      products = props.dataItems;
      productsLength = props.dataItems?.length;
    } else {
      products = props.dataItems["proizvodi"]?.filter(
        (product) => product.categoryID === +urlParam
      );
      productsLength = products?.length;
    }

    if (productsLength > 0) {
      if (paginationParam.length < 2) {
        // Ako nema query parametar page i ako ima artikala

        return { previousOption: false, nextOption: true };
      } else {
        const pageNum = +paginationParam[1];

        if (productsLength > pageNum * productsPerPage) {
          // Ako pregledava po stranici brojeve i nije do kraja paginacije došao
          return { previousOption: true, nextOption: true };
        } else {
          // Ako je do kraja paginacije došao
          if (pageNum * productsPerPage - productsLength < productsPerPage) {
            // Ako si na zadnjoj stranici paginacije

            return { previousOption: true, nextOption: false };
          } else {
            return {
              previousOption: false,
              nextOption: false,
            };
          }
        }
      }
    }
  };
  const [searchValue, setSearchValue] = useState("");
  const [paginationOptions, setPaginationOptions] = useState(
    getPaginationOptions()
  );
  const [products, setProducts] = useState(getProductsForShow());
  const [isSearching, setIsSearching] = useState(false);
  if (products.length === 0 && !isSearching) {
    // Ako dodje na stranicu a nema artikala
    window.location.href = "/katalog";
  }
  if (props.dataItems?.length === 0) {
    window.location.href = "/";
  }
  useEffect(() => {
    window.scrollTo(0, 120);
    setSortOrderId(0);
    if (counter === 1 && urlParam) {
      navigate("/kategorija/" + urlParam);
    }
    if (counter === 1 && !urlParam) {
      navigate("/miniplast-akcije");
    }
    if (counter > 1) {
      navigate("?page=" + counter);
    }
  }, [counter]);

  useEffect(() => {
    setProducts(getProductsForShow());
  }, [urlParam]);
  const filterProductsBySearch = (items, searchVal) => {
    const newProd = items?.filter(
      (item) =>
        item?.title.toLowerCase()?.includes(searchVal.toLowerCase()) ||
        item.productCode?.includes(searchVal)
    );
    return newProd;
  };

  useEffect(() => {
    if (isInputFocused) {
      const filteredProducts = filterProductsBySearch(products, searchValue);

      if (searchValue.length === 0) {
        setProducts(getProductsForShow());
      } else {
        setProducts(filteredProducts);
      }
    }
  }, [searchValue]);
  useEffect(() => {
    if (parseInt(sortOrderId) === 0) {
      return;
    }
    if (parseInt(sortOrderId) === 1) {
      let filteredItems = products.slice();

      filteredItems = filteredItems.sort((a, b) => {
        if (urlParam) {
          return a.price - b.price;
        } else {
          return a.salePrice - b.salePrice;
        }
      });
      setProducts(filteredItems);
    }
    if (parseInt(sortOrderId) === 2) {
      let filteredItems = products.slice();

      filteredItems = filteredItems.sort((a, b) => {
        if (urlParam) {
          return b.price - a.price;
        } else {
          return b.salePrice - a.salePrice;
        }
      });

      setProducts(filteredItems);
    }
  }, [sortOrderId]);

  const goToNextPage = () => {
    if (!paginationOptions.nextOption) {
      return;
    }
    setCounter((prevValue) => prevValue + 1);
  };
  const goToPreviousPage = () => {
    if (!paginationOptions.previousOption) {
      return;
    }
    setCounter((prevValue) => prevValue - 1);
  };
  return (
    <div className="categories-menu">
      <div className="categories-menu__title">
        <span>{categoryTitle}</span>
      </div>
      <div className="categories-menu__filter">
        <div className="categories-menu__select-filter">
          <select
            value={sortOrderId}
            onChange={(e) => {
              setSortOrderId(e.target.value);
            }}
          >
            <option value={0}>Sortiraj prema:</option>
            <option value={1}>Cijena - uzlazno</option>
            <option value={2}>Cijena - silazno</option>
          </select>
        </div>
        <div className="categories-menu__search-filter">
          <FaSearch className="search-icon" />
          <input
            type="text"
            placeholder="Pretraži ovdje"
            onFocus={() => setIsSearching(true)}
            onChange={(e) => setSearchValue(e.target.value)}
            onFocusCapture={() => {
              setSortOrderId(0);
              setIsInputFocused(true);
            }}
          />
        </div>
      </div>
      <div className="categories-menu__products">
        {isLoadingProducts ? (
          <Spinner width={30} height={30} />
        ) : (
          products?.map((product) => {
            return (
              <Product
                key={product.id}
                id={product.id}
                imageUrl={product.image}
                isActive={product.isActive}
                price={product.price}
                salePrice={product.salePrice}
                name={product.title}
                code={product.productCode}
              />
            );
          })
        )}
      </div>
      {productsOfCategory.length > productsPerPage && (
        <div className="categories-product_pagination">
          <span
            className={`categories-product_pagination__previous ${
              !paginationOptions.previousOption ? "disable" : ""
            }`}
            onClick={goToPreviousPage}
          >
            Prethodno
          </span>
          <span
            className={`categories-product_pagination__next ${
              !paginationOptions.nextOption ? "disable" : ""
            }`}
            onClick={goToNextPage}
          >
            Sljedeća
          </span>
        </div>
      )}
    </div>
  );
}
