import React, { useState } from "react";
import { MdLocationOn } from "react-icons/md";
import { FaRegClock } from "react-icons/fa";
import { LuHeartHandshake } from "react-icons/lu";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import "./Contact.scss";
import emailjs from "@emailjs/browser";
import Spinner from "../../UI/Spinner/Spinner";
export default function Contact() {
  const [emailVal, setEmailVal] = useState("");
  const [nameVal, setNameVal] = useState("");
  const [subjectVal, setSubjectVal] = useState("");
  const [msgVal, setMsgVal] = useState("");
  const [msg, setMsg] = useState({
    value: "",
    classname: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const submitContactHandler = async (e) => {
         e.preventDefault();
    setIsLoading(true);
    setMsg({ value: "", classname: "" });
    if(nameVal?.length === 0 || emailVal?.length === 0){
     setMsg({value: "Ime i Email su obavezna polja", classname: "error"});
     setIsLoading(false);
     return;
}

  try {
const response = await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      );

if(response) {
   setMsg({ value: "Upit uspješno poslan.", classname: "success" });
}
}catch (error) {
  if(error) {
  setMsg({
            value: "Došlo je do greške prilikom slanja",
            classname: "error",
          })
}

}


    setIsLoading(false);
  };
  return (
    <div className="contact-container">
      <div className="main-content">
        <div className="contact-form">
          <div className="contact-form__wrapper"></div>
          <form className="form" onSubmit={submitContactHandler}>
            <div className="form-headline">
              <h2>Pošalji svoj upit</h2>
            </div>
            <div className="contact__name">
              <input
                type="text"
                placeholder="Vaše ime (obavezno)"
                name="from_name"
                onChange={(e) => setNameVal(e.target.value)}
              />
            </div>
            <div className="contact__email">
              <input
                type="text"
                placeholder="Vaš email (obavezno)"
                onChange={(e) => setEmailVal(e.target.value)}
                name="from_email"              
/>
            </div>
            <div className="contact__subject">
              <input
                type="text"
                placeholder="Predmet"
                name="subject"
                onChange={(e) => setSubjectVal(e.target.value)}
              />
            </div>
            <div className="contact__message">
              <textarea
                placeholder="Vaša poruka"
                onChange={(e) => setMsgVal(e.target.value)}
                name="message"
              ></textarea>
            </div>
            {msg.value.length > 0 && (
              <div className="contact-message">
                <span className={msg.classname}>{msg.value}</span>
              </div>
            )}
            <div className="action">
              <button type="submit">
                {isLoading ? <Spinner width={17} height={17} /> : "Pošalji"}
              </button>
            </div>
          </form>
        </div>
        <div className="company-info">
          <div className="company__location">
            <MdLocationOn className="location-icon" />
            <div className="company__text">
              <h3>Lokacija i kontakt</h3>
              <span>Miniplast d.o.o.</span>
              <span>Trnska Cesta 74a, Široki Brijeg, Bosna i Hercegovina</span>
              <span>Tel / Fax: +387 39 706 230</span>
              <span>Tel: +387 39 704 129</span>
              <span>PDV: 4272156020006</span>
              <span>ID: 272156020006</span>
              <span>miniplast@tel.net.ba</span>
            </div>
          </div>
          <div className="company__business-hours">
            <FaRegClock className="clock-icon" />
            <div className="company__hours">
              <h3>Radno vrijeme</h3>
              <span>Ponedjeljak - petak</span>
              <span>08:00-16:00</span>
              <span>Subota</span>
              <span>08:00-13:00</span>
            </div>
          </div>
          <div className="company__social-media">
            <LuHeartHandshake className="heart-icon" />
            <div className="company__media">
              <h3>Društvene mreže</h3>
              <div className="media-list">
                <a
                  href="https://www.facebook.com/MiniplastDOO/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <FaFacebookF className="facebook-icon" />
                </a>
                <a
                  href="https://www.instagram.com/miniplast_doo/"
                  rel="noreferrer"
                  target="_blank"
                >
                  <FaInstagram className="instagram-icon" />
                </a>
                <a href="/#" rel="noreferrer" target="_blank">
                  <FaLinkedinIn className="linkedin-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="company__map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2899.8942499121235!2d17.56547847661947!3d43.379235170106455!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x134b3c316d52645d%3A0xf852fb32a54747a9!2sMiniplast!5e0!3m2!1sen!2sba!4v1708538490614!5m2!1sen!2sba"
          width="700"
          height="500"
          loading="lazy"
          title="miniplast"
        ></iframe>
      </div>
    </div>
  );
}
