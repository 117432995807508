export const partnership = [
  { id: 1, name: "Voga", image: "voga.jpg", link: "https://voga.si/" },
  {
    id: 2,
    name: "Titus",
    image: "titus-sl.png",
    link: "https://www.titusplus.com/int/en",
  },
  {
    id: 3,
    name: "Spax",
    image: "spax.png",
    link: "https://www.spax.com/pc-en.html",
  },
  {
    id: 4,
    name: "Sink Solution",
    image: "sink-solution.png",
    link: "https://sink-solution.com/",
  },
  {
    id: 5,
    name: "Sige Spa",
    image: "sige-spa.png",
    link: "https://www.sige-spa.it/en/home/",
  },
  {
    id: 6,
    name: "Paludet Mario",
    image: "paludet-mario.jpg",
    link: "https://www.paludetmario.it/",
  },
  {
    id: 7,
    name: "Syskor",
    image: "syskor.png",
    link: "https://www.syskor.es/",
  },
  {
    id: 8,
    name: "Latho",
    image: "latho.png",
    link: "https://www.latho.it/en/",
  },
  {
    id: 9,
    name: "Hafele",
    image: "hafele.jpg",
    link: "https://www.hafele.com/us/en/",
  },
  {
    id: 10,
    name: "Gvm Soluzioni",
    image: "gvm-soluzioni.webp",
    link: "https://www.gvmsoluzioni.net/",
  },
  {
    id: 11,
    name: "Giusti Spa",
    image: "giusti-spa.jpg",
    link: "https://www.giustiwings.com/",
  },
  { id: 12, name: "Emuca", image: "emuca.png", link: "https://www.emuca.es/" },
  {
    id: 13,
    name: "Elica",
    image: "elica.jpg",
    link: "https://www.elica.com/BA-en/extractor-hobs",
  },
  {
    id: 14,
    name: "Ceam",
    image: "ceam.jpg",
    link: "https://www.ceamitalia.it/en/",
  },
  {
    id: 15,
    name: "Bolis Italia",
    image: "bolis-italia.jpg",
    link: "https://bolisitalia.com/",
  },
  {
    id: 16,
    name: "Blum",
    image: "blum.png",
    link: "https://www.blum.com/us/en/",
  },
  {
    id: 17,
    name: "ASD Laminat",
    image: "asd-laminat.png",
    link: "https://www.asdlaminat.com/",
  },
];
