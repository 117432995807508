import React from "react";
import "./Spinner.scss";
export default function Spinner({ width, height }) {
  return (
    <div
      className="spinner"
      style={{ width: `${width}px`, height: `${height}px` }}
    ></div>
  );
}
