import React, { useEffect, useRef, useState } from "react";
import { partnership } from "../../util/partnership";
import "./Agency.scss";
import { BsInfoCircle } from "react-icons/bs";
export default function Agency() {
  const partners = partnership;
  const headerContentRef = useRef(null);
  const [extraClassName, setExtraClassName] = useState("");
  useEffect(() => {
    setTimeout(() => {
      setExtraClassName("move");
    }, 500);
  }, []);
  return (
    <div className="partners-page">
      <div className="partners-page-info" ref={headerContentRef}>
        <div className={`partners-img ${extraClassName}`}>
          <img src="./kvake.jpg" alt="miniplast-interior" loading="lazy" />
        </div>
        <div className={`partners-information ${extraClassName}`}>
          <span className="info-icon">
            <BsInfoCircle />
          </span>
          <p>
            Zastupnici smo više renomiranih tvrtki iz Njemačke, Italije i
            Slovenije sa čijim proizvodima pokušavamo našim klijentima pomoći u
            idejnim rješenjima, garantirati nivo kvalitete i osigurati
            dogovorene rokove isporuke. Na njihovim službenim web stranicama
            koje su navedene ispod možete vidjeti širok asortiman proizvoda koje
            uvozimo.
          </p>
        </div>
      </div>
      <div className="partners">
        {partners.map((partner) => {
          return (
            <a
              key={partner.id}
              href={partner.link}
              target="_blank"
              rel="noreferrer"
            >
              <img
                loading="lazy"
                src={`./partners/${partner.image}`}
                alt={partner.name}
              />
            </a>
          );
        })}
      </div>
    </div>
  );
}
