import "./App.css";
import { Route, Routes } from "react-router-dom";
import Layout from "./UI/Layout/Layout";
import { useEffect, useState } from "react";
import MobileLayout from "./UI/MobileLayout/MobileLayout";
import Home from "./components/Home/Home";
import Catalog from "./components/Catalog/Catalog";
import Agency from "./components/Agency/Agency";
import About from "./components/About/About";
import Contact from "./components/Contact/Contact";
import Sale from "./components/Sale/Sale";
import CategoriesMenu from "./components/CategoriesMenu/CategoriesMenu";
import ProductItem from "./components/ProductItem/ProductItem";
function App() {
  const isMobileDevice = () => {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  };
console.log('render');
  const [isMobile] = useState(isMobileDevice());
  const [data, setData] = useState({});
  useEffect(() => {
    const fetchMainApi = async () => {
      const response = await fetch(process.env.REACT_APP_BASE_URL + "/api/all");
      const data = await response.json();
      if (data && data.data) {
        setData(data.data);
      }
    };

    fetchMainApi();
  }, []);
  if (Object.keys(data).length === 0) {
    return;
  }
  const saleProducts = data["proizvodi"].filter(
    (product) => parseFloat(product?.salePrice) > 0
  );
  const routes = (
    <Routes>
      <Route path="/" exact element={<Home isMobile={isMobile} />} />
      <Route path="/katalog" element={<Catalog dataItems={data} />} />
      <Route path="/zastupništvo" element={<Agency />} />
      <Route path="/o-nama" element={<About />} />
      <Route path="/kontakt" element={<Contact />} />
      <Route
        path="/miniplast-akcije"
        element={<CategoriesMenu dataItems={saleProducts} />}
      />
      <Route
        path="/kategorija/:categoryId"
        element={<CategoriesMenu dataItems={data} />}
      />
      <Route
        path="/proizvod/:productId"
        element={<ProductItem dataItems={data} isMobile={isMobile} />}
      />
    </Routes>
  );

  return (
    <div className="App">
      {isMobile ? (
        <MobileLayout saleProducts={saleProducts}>{routes}</MobileLayout>
      ) : (
        <Layout saleProducts={saleProducts}>{routes}</Layout>
      )}
      {/* {routes} */}
    </div>
  );
}

export default App;
