import React, { useEffect, useRef, useState } from "react";
import "./About.scss";
export default function About() {
  const [extraClassName, setExtraClassName] = useState("");
  const infoNumbersRef = useRef(null);
  const [isNumbersShowed, setIsNumbersShowed] = useState(false);
  const [employeesCounter, setEmployeesCounter] = useState(0);
  const [yearsCounter, setYearsCounter] = useState(0);
  const [productsCounter, setProductsCounter] = useState(100);
  useEffect(() => {
    setTimeout(() => {
      setExtraClassName("move-articles");
    }, 500);
  }, []);
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.3,
    };

    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          setIsNumbersShowed(true);
          observer.disconnect();
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    if (infoNumbersRef.current) {
      observer.observe(infoNumbersRef.current);
    }

    // Cleanup function
    return () => {
      if (infoNumbersRef.current) {
        observer.unobserve(infoNumbersRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (isNumbersShowed) {
      const interval = setInterval(() => {
        if (employeesCounter <= 4) {
          setEmployeesCounter((prevCount) => prevCount + 1);
        }
      }, 100); // Change the interval duration as needed

      return () => clearInterval(interval);
    }
  }, [isNumbersShowed, employeesCounter]);
  useEffect(() => {
    if (isNumbersShowed) {
      const interval = setInterval(() => {
        if (yearsCounter <= 19) {
          setYearsCounter((prevCount) => prevCount + 1);
        }
      }, 100); // Change the interval duration as needed

      return () => clearInterval(interval);
    }
  }, [isNumbersShowed, yearsCounter]);
  useEffect(() => {
    if (isNumbersShowed) {
      const interval = setInterval(() => {
        if (productsCounter <= 1499) {
          setProductsCounter((prevCount) => prevCount + 70);
        }
      }, 100); // Change the interval duration as needed

      return () => clearInterval(interval);
    }
  }, [isNumbersShowed, productsCounter]);

  return (
    <div className="about-page">
      <div className="about-page__main">
        <div className="about-page-wrapper"></div>
        <div className="about-page__company-img">
          <img src="./zgrada.jpg" alt="miniplast-building" />
        </div>
        <div className="about-page__articles">
          <p className={`about-page__first-article ${extraClassName}`}>
            Tvrtka Miniplast d.o.o. sa sjedištem u Širokom Brijegu se već dugi
            niz godina bavi uvozom i prodajom okova za izradu namještaja. Cilj
            nam je zadovoljiti potrebe, želje i mogućnosti naših kupaca.
          </p>
          <p className={`about-page__second-article ${extraClassName}`}>
            Uvoznik smo brojnih renomiranih tvrtki iz Njemačke, Italije i
            Slovenije sa čijim proizvodima pokušavamo našim klijentima pomoći u
            idejnim rješenjima, garantirati nivo kvalitete i osigurati
            dogovorene rokove isporuke.
          </p>
          <p className={`about-page__third-article ${extraClassName}`}>
            Miniplast je moderna tvrtka koja prati svjetske trendove u dizajnu
            namještaja. U katalogu možete pronaći našu ponudu kuhinjskih napa,
            okov za namještaj, klizni okov za ormare i plakare , ručkice za
            namještaj, okov za uredski namještaj, LED rasvjetu i još mnogo toga.
          </p>
          <p className={`about-page__fourth-article ${extraClassName}`}>
            Isto tako Vas pozivamo da posjetite naš izložbeni prostor u kojem
            možete pogledati najnovije uzorke artikala te sa našim osobljem
            pronaći odgovarajuće rješenje za vaše potrebe. Za veliki asortiman
            proizvoda koje nudimo provjeri u našem
            <a href="/katalog">web katalogu</a>.
          </p>
        </div>
      </div>
      <div className="miniplast-info-numbers" ref={infoNumbersRef}>
        <div className="miniplast-info-numbers">
          <div className="miniplast-employees">
            <span
              className={`miniplast-number ${isNumbersShowed ? "animate" : ""}`}
            >
              {employeesCounter}+
            </span>
            <span className="miniplast-label">Zaposlenih djelatnika</span>
          </div>
          <div className="miniplast-since">
            <span
              className={`miniplast-number ${isNumbersShowed ? "animate" : ""}`}
            >
              {yearsCounter}+
            </span>
            <span className="miniplast-label">Godina s Vama</span>
          </div>
          <div className="miniplast-products">
            <span
              className={`miniplast-number ${isNumbersShowed ? "animate" : ""}`}
            >
              {productsCounter}+
            </span>
            <span className="miniplast-label">Artikala</span>
          </div>
        </div>
      </div>
    </div>
  );
}
