import React from "react";
import Category from "../../UI/Category/Category";
import "./Catalog.scss";
export default function Catalog(props) {
  const getProductsLength = (catId) => {
    return props.dataItems["proizvodi"]?.filter(
      (product) => product.categoryID === catId
    )?.length;
  };
  return (
    <div className="catalog-page">
      <span className="catalog-title">Kategorije</span>
      <div className="catalog">
        {props?.dataItems["kategorije"].length > 0 ? (
          props.dataItems["kategorije"]
            ?.sort((a, b) => a.ordinalNum - b.ordinalNum)
            ?.map((category) => {
              return (
                <Category
                  key={category.id}
                  name={category.title}
                  imageUrl={category.image}
                  productsLength={getProductsLength(category.id)}
                  catId={category.id}
                />
              );
            })
        ) : (
          <div className="empty-container">
            <span className="empty-container__msg">Nije pronađeno</span>
          </div>
        )}
      </div>
    </div>
  );
}
