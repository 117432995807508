import React from "react";
import "./Category.scss";
import { useNavigate } from "react-router-dom";

export default function Category(props) {
  const navigate = useNavigate();
  const categoryHandler = () => {
    if (props.productsLength > 0) {
      navigate("/kategorija/" + props.catId);
    }
  };
  return (
    <div className="category-item" onClick={categoryHandler}>
      <div className="category-item__img">
        <img
          src={`${process.env.REACT_APP_BASE_URL}/` + props.imageUrl}
          alt={props.name}
        />
      </div>
      <div className="category-item__info">
        <span className="category-item__info_title">{props.name}</span>
        <span className="category-item__info_product-length">
          Broj proizvoda: {props.productsLength}
        </span>
      </div>
    </div>
  );
}
