import React, { useEffect, useRef, useState } from "react";
import "./ProductItem.scss";
import { useParams } from "react-router-dom";
import "./ProductItem.scss";
import ProductSlider from "../../UI/ProductSlider/ProductSlider";
import { AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";

export default function ProductItem(props) {
  const params = useParams();
  const [imageIndex, setImageIndex] = useState(0);
  const carouselRef = useRef(null);
  const urlParam = params.productId;
  const findedProduct = props.dataItems["proizvodi"]?.find(
    (product) => product.id === +urlParam
  );
  const [extraClassImg, setExtraClassImg] = useState("");
  const [imageEndName, setImageEndName] = useState("");
  useEffect(() => {
    // const newPosition = imageIndex * 100 + "%";
    // carouselRef.current.style.transform = `translateX(${newPosition})`;
    setExtraClassImg("hide");
    setTimeout(() => {
      if (imageIndex === 0) {
        setImageEndName(findedProduct.image);
      } else {
        setImageEndName(findedProduct.dimensionPic);
      }
      setExtraClassImg("");
    }, 300);
  }, [carouselRef.current, imageIndex, urlParam]);

  let sameCategoryProducts = props.dataItems["proizvodi"]
    ?.filter((product) => product.categoryID === findedProduct.categoryID)
    .filter((product) => product.id !== +urlParam);
  const categoryTitle = props.dataItems["kategorije"]?.find(
    (category) => category.id === findedProduct.categoryID
  )?.title;
  const splittedColors = findedProduct?.avaliableColors?.split(",");

  const isProductOnSale =
    parseFloat(findedProduct.salePrice) > 0 ? true : false;
  return props.isMobile ? (
    <>
      <div className="product-item">
        <div className="product-item__carousel" ref={carouselRef}>
          <img
            src={process.env.REACT_APP_BASE_URL + "/" + imageEndName}
            alt={findedProduct.title}
            className={extraClassImg}
          />
        </div>
        {findedProduct.dimensionPic?.length > 0 && (
          <div className="carousel-arrows">
            <div
              className={`carousel-arrow_left ${
                imageIndex === 0 ? "disabled" : ""
              }`}
            >
              <span onClick={() => setImageIndex(0)}>
                {<AiOutlineArrowLeft />}
              </span>
            </div>
            <div
              className={`carousel-arrow_right ${
                imageIndex === -1 ? "disabled" : ""
              }`}
            >
              <span onClick={() => setImageIndex(-1)}>
                {<AiOutlineArrowRight />}
              </span>
            </div>
          </div>
        )}
        <div className="content">
          <div className="product-item__main-info">
            <div className="product-item__title_and_category">
              <span className="product-item__title">{findedProduct.title}</span>
              <span className="product-item__category">{categoryTitle}</span>
            </div>
            <span className="product-item__code">
              {findedProduct.productCode}
            </span>
          </div>
          <div className="product-item__prices">
            <span
              className={`product-item__price ${
                isProductOnSale ? "inactive" : ""
              }`}
            >
              {findedProduct.price.toFixed(2)} KM
            </span>
            {isProductOnSale && (
              <span className="product-item__sale-price">
                {findedProduct.salePrice.toFixed(2)} KM
              </span>
            )}
          </div>

          <div className="product-item__other-details">
            <span className="product-item__other-details__title">Detalji</span>
            <div className="product-item__info">
              <span className="product-item__label">Boje</span>
              <span className="product-item__value">
                {splittedColors.map((color) => {
                  return (
                    <span key={color} className="color">
                      {color},
                    </span>
                  );
                })}
              </span>
            </div>
            {findedProduct.productMaterial.length > 0 && (
              <div className="product-item__info">
                <span className="product-item__label">Materijal</span>
                <span className="product-item__value">
                  {findedProduct.productMaterial}
                </span>
              </div>
            )}
            {findedProduct.productLength.length > 0 && (
              <div className="product-item__info">
                <span className="product-item__label">Dužina</span>
                <span className="product-item__value">
                  {findedProduct.productLength}
                </span>
              </div>
            )}
            {findedProduct.productSpace.length > 0 && (
              <div className="product-item__info">
                <span className="product-item__label">Razmak</span>
                <span className="product-item__value">
                  {findedProduct.productSpace}
                </span>
              </div>
            )}
            {findedProduct.productWidth.length > 0 && (
              <div className="product-item__info">
                <span className="product-item__label">Širina</span>
                <span className="product-item__value">
                  {findedProduct.productWidth}
                </span>
              </div>
            )}
            {findedProduct.productHeight.length > 0 && (
              <div className="product-item__info">
                <span className="product-item__label">Visina</span>
                <span className="product-item__value">
                  {findedProduct.productHeight}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <ProductSlider
        categoryTitle={categoryTitle}
        products={sameCategoryProducts}
        isMobile={props.isMobile}
        setImageIndex={setImageIndex}
      />
    </>
  ) : (
    <>
      <div className="product-item">
        <div className="product-item__left">
          <div className="product-item__carousel" ref={carouselRef}>
            <img
              src={process.env.REACT_APP_BASE_URL + "/" + imageEndName}
              alt={findedProduct.title}
              className={extraClassImg}
            />
          </div>
          {findedProduct.dimensionPic?.length > 0 && (
            <div className="carousel-arrows">
              <span
                onClick={() => setImageIndex(0)}
                className={`carousel-arrow_left ${
                  imageIndex === 0 ? "disabled" : ""
                }`}
              >
                {<AiOutlineArrowLeft />}
              </span>
              <span
                onClick={() => setImageIndex(-1)}
                className={`carousel-arrow_right ${
                  imageIndex === -1 ? "disabled" : ""
                }`}
              >
                {<AiOutlineArrowRight />}
              </span>
            </div>
          )}
          <div className="product-item__main-info">
            <div className="product-item__title_and_category">
              <span className="product-item__title">{findedProduct.title}</span>
              <span className="product-item__category">{categoryTitle}</span>
            </div>
            <span className="product-item__code">
              Šifra: {findedProduct.productCode}
            </span>
          </div>
          <div className="product-item__prices">
            <span
              className={`product-item__price ${
                isProductOnSale ? "inactive" : ""
              }`}
            >
              {findedProduct.price.toFixed(2)} KM
            </span>
            {isProductOnSale && (
              <span className="product-item__sale-price">
                {findedProduct.salePrice.toFixed(2)} KM
              </span>
            )}
          </div>
        </div>
        <div className="content">
          <div className="product-item__other-details">
            <span className="product-item__other-details__title">Detalji</span>
            <div className="product-item__info">
              <span className="product-item__label">Boje</span>
              <span className="product-item__value">
                {splittedColors.map((color) => {
                  return (
                    <span key={color} className="color">
                      {color},
                    </span>
                  );
                })}
              </span>
            </div>
            {findedProduct.productMaterial.length > 0 && (
              <div className="product-item__info">
                <span className="product-item__label">Materijal</span>
                <span className="product-item__value">
                  {findedProduct.productMaterial}
                </span>
              </div>
            )}
            {findedProduct.productLength.length > 0 && (
              <div className="product-item__info">
                <span className="product-item__label">Dužina</span>
                <span className="product-item__value">
                  {findedProduct.productLength}
                </span>
              </div>
            )}
            {findedProduct.productSpace.length > 0 && (
              <div className="product-item__info">
                <span className="product-item__label">Razmak</span>
                <span className="product-item__value">
                  {findedProduct.productSpace}
                </span>
              </div>
            )}
            {findedProduct.productWidth.length > 0 && (
              <div className="product-item__info">
                <span className="product-item__label">Širina</span>
                <span className="product-item__value">
                  {findedProduct.productWidth}
                </span>
              </div>
            )}
            {findedProduct.productHeight.length > 0 && (
              <div className="product-item__info">
                <span className="product-item__label">Visina</span>
                <span className="product-item__value">
                  {findedProduct.productHeight}
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
      <ProductSlider
        categoryTitle={categoryTitle}
        products={sameCategoryProducts}
        isMobile={props.isMobile}
        setImageIndex={setImageIndex}
      />
    </>
  );
}
